import './App.css';
import LandingPage from './components/LandingPage';
import Footer from './components/Footer';
// import Header from './components/Header';
// import Studies from './components/Studies';
// import About from './components/About';

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <LandingPage />
      {/* <Studies />
      <About /> */}
      <Footer />
    </div>
  );
}

export default App;